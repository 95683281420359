<template>
  <b-navbar
    :class="{ shadow: scrollPosition > 50 }"
    toggleable="lg"
    fixed="top"
    type="light"
  >
    <div class="container">
      <b-navbar-brand href="#">
        <router-link to="/">
          <img
            src="/assets/gruposable.svg"
            width="70%"
            alt="Logo Grupo Sable"
          />
        </router-link>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto mt-4 mt-lg-0">
          <b-nav-item
            v-on:click="scrollToTop"
            active-class="active"
            to="/ecosistema"
          >
            Ecosistema
          </b-nav-item>

          <b-nav-item
            v-on:click="scrollToTop"
            active-class="active"
            to="/quienessomos"
          >
            Quiénes Somos
          </b-nav-item>

          <b-nav-item
            v-on:click="scrollToTop"
            active-class="active"
            to="/vision"
          >
            Visión y Estrategia
          </b-nav-item>

          <b-nav-item
            v-on:click="scrollToTop"
            active-class="active"
            to="/contacto"
          >
            Contacto
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </div>
  </b-navbar>
</template>

<script>
export default {
  data() {
    return {
      scrollPosition: null,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
