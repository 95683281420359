<template>
  <div class="bg-footer">
    <b-container>
      <b-row>
        <b-col cols="12" md="3" class="footer-header">
          <img
            src="/assets/gruposable.svg"
            width="40%"
            alt="Logo Grupo Sable"
          />
        </b-col>

        <b-col md="auto" class="ml-auto">
          <ul class="footer-navbar navbar-nav small">
            <li class="nav-item">
              <router-link
                v-on:click="scrollToTop"
                to="/ecosistema"
                class="nav-link text-black"
                >Ecosistema</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                v-on:click="scrollToTop"
                to="/quienessomos"
                class="nav-link text-black"
                >Quiénes Somos</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                v-on:click="scrollToTop"
                to="/vision"
                class="nav-link text-black"
                >Visión y Estrategia</router-link
              >
            </li>
            <li class="nav-item mr-0">
              <router-link
                v-on:click="scrollToTop"
                to="/contacto"
                class="nav-link text-black"
                >Contacto</router-link
              >
            </li>
          </ul>
        </b-col>
      </b-row>
      <b-row class="">
        <b-col xs="12" md="6" lg="4">
          <div class="mt-4 font-weight-regular">
            <span class="footer-country-title">CHILE</span>
            <p class="footer-direction mb-1">
              Av. Apoquindo Nº 4499, piso 6, 10 y 15, Las Condes, Santiago.
            </p>
            <p class="footer-direction">
              Errázuriz Nº 755, oficina 602, 603, 604, 605, Valparaíso, Chile.
            </p>
          </div>
          <div class="mt-4 font-weight-regular">
            <span class="footer-country-title">COLOMBIA</span>
            <p class="footer-direction">
              Carrera 11a #93-52, Oficina 304, Edificio Torre 93, Bogotá.
            </p>
          </div>
        </b-col>
        <b-col xs="12" md="6">
          <div class="mt-4 font-weight-regular">
            <span class="footer-country-title">PERÚ</span>
            <p class="footer-direction">
              Calle Amador Merino Reyna 465 Of. 601–A. San Isidro. Lima.
            </p>
          </div>
          <div class="mt-4 font-weight-regular">
            <span class="footer-country-title">MÉXICO</span>
            <p class="footer-direction">
              Darwin 74 Piso 2, Of. 2A • Colonia Nva. Anzures Ciudad de México •
              C.P.115.
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col sm="12" md="6">
          <div class="footer-rrss mt-4">
            <p class="small mb-0">
              <a href="mailto:contacto@sable.lat">contacto@sable.lat</a>
            </p>

            <div class="ml-4">
              <a
                href="https://www.linkedin.com/company/sable-latam/"
                target="_blank"
              >
                <img src="/assets/YouTube.webp" alt="Youtube" class="ml-3" />
              </a>
              <a
                href="https://www.linkedin.com/company/sable-latam/"
                target="_blank"
              >
                <img src="/assets/LinkedIn.webp" alt="LinkedIn" class="ml-3" />
              </a>
              <!-- <img src="/assets/YouTube.png" alt="Youtube" class="ml-3"> -->
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6" class="mt-4">
          <p class="small text-muted text-right">Copyright 2021 Grupo sable</p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scrollPosition: null,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
